// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/aboutPageTemplate.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-contact-page-template-js": () => import("./../../../src/templates/contactPageTemplate.js" /* webpackChunkName: "component---src-templates-contact-page-template-js" */),
  "component---src-templates-customise-page-template-js": () => import("./../../../src/templates/customisePageTemplate.js" /* webpackChunkName: "component---src-templates-customise-page-template-js" */),
  "component---src-templates-home-page-template-js": () => import("./../../../src/templates/homePageTemplate.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-partners-page-template-js": () => import("./../../../src/templates/partnersPageTemplate.js" /* webpackChunkName: "component---src-templates-partners-page-template-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-retailers-page-template-js": () => import("./../../../src/templates/retailersPageTemplate.js" /* webpackChunkName: "component---src-templates-retailers-page-template-js" */),
  "component---src-templates-shop-category-home-js": () => import("./../../../src/templates/shopCategoryHome.js" /* webpackChunkName: "component---src-templates-shop-category-home-js" */),
  "component---src-templates-shop-home-js": () => import("./../../../src/templates/shopHome.js" /* webpackChunkName: "component---src-templates-shop-home-js" */)
}

